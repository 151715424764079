import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import "./normalize.css"

import MiniHeader from "../components/miniheader/miniheader"
import Divider from "../components/divider/divider"
import Menu from "../components/menu/menu"
import Bloglist from "../components/bloglist/bloglist"
import Footer from "../components/footer/footer"
import Fever from "../components/fever/fever"
import Contact from "../components/contact/contact"

const Latinfever = () => {
	return (

		<Layout>
			<Seo title="Latin Fever - taneční kurz pro ženy" description="Kurz latinsko-amerických tanců pro ženy a slečny, které si chtějí zatancovat bez partnera" />

			<MiniHeader text='fever'></MiniHeader>
			<Fever></Fever>
			<Contact></Contact>
			<Bloglist></Bloglist>
			<Divider></Divider>
			<Menu></Menu>
			<Footer></Footer>

		</Layout>

	)
}

export default Latinfever