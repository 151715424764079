import React, { useEffect } from "react"

import "../course/course.scss"
import "../../pages/index.scss"

const Fever = () => {

	useEffect(() => {
		document.body.classList.add("specialBackground");
		document.body.classList.add("feverBackground");
		return () => {
			document.body.classList.remove("specialBackground");
			document.body.classList.remove("feverBackground");
		}
	}, []);
	
	return (

		<div className="tz-course">

			<div className="container mb3">

				<span className="mt1 warning" data-sal="fade" data-sal-duration="1000">open class</span>
				<h2 className="mt1 mb3 w50" data-sal="fade" data-sal-duration="1000">Pro všechny ženy a&nbsp;slečny, které si chtějí zatancovat bez&nbsp;partnera</h2>

				<div className="container-50">
					<div className="tz-course__list" data-sal="fade" data-sal-duration="1000">
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">neděle</div>
								<div className="tz-course__content">18:15 - 19:15</div>
							</div>
						</div>
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">cena/lekci:</div>
								<div className="tz-course__content">200,-</div>
							</div>
						</div>
					</div>
					<div>
						<div data-sal="fade" data-sal-duration="1000">
							<h3>Přihlašte se:</h3>
							<ul className="tz-list">
								<li className="tz-list__item highlighted-text"><span>✅ na emailu <a className="tz-list__link" href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></span></li>
								<li className="tz-list__item highlighted-text"><span>✅ na messengeru stránky <a className="tz-list__link" href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">Taneční Zlonín</a></span></li>
								<li className="tz-list__item highlighted-text"><span>✅ na telefonu/WhatsApp <a className="tz-list__link" href="tel:+420732560838">+420 732 560 838</a></span></li>
							</ul>
						</div>
					</div>
				</div>

			</div>

			<div className="container mt5">

				<div>
					<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Latin Fever je kurz latinsko-amerických tanců, na&nbsp;kterém si postupně osvojíme tance Cha-Cha-Cha, Samba, Jive, Rumba, Salsa, Paso Doble. Naučíme se propojit pohyb s&nbsp;elegancí, smyslností a&nbsp;temperamentem 🔥 a&nbsp;to vše na&nbsp;krásnou latinskou hudbu 🎶</span></p>
					<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Odreagujete se od každodenní rutiny, zbavíte se přebytečného stresu a&nbsp;načerpáte pořádnou dávku pozitivní energie ☀️</span></p>
					<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Kurz latiny je mimo jiné skvělá varianta kardio cvičení, které dokonale formuje postavu 💪</span></p>
					<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Přijďte si se&nbsp;mnou zatančit, těším se na&nbsp;vás 💃❤</span></p>
					<p data-sal="fade" data-sal-duration="1000">Irina</p>
				</div>

			</div>


			<div className="tz-info">
				<div className="container tz-color-black">
					<h3 className="mt1 mb3 tz-color-black" data-sal="fade" data-sal-duration="1000">Organizační informace k&nbsp;tanečnímu kurzu Latin&nbsp;Fever</h3>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Otevření sálu a&nbsp;vstup</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Vstup na sál je bočním vchodem. Taneční sál bude otevřen již od&nbsp;cca&nbsp;18:00. Prosíme, doražte dostatečně před začátkem, abychom mohli včas začít a&nbsp;už byli všichni připraveni na&nbsp;místě.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Odkládání a&nbsp;přezouvání</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Všechny své věci si s&nbsp;sebou noste na&nbsp;sál, kde jsou k&nbsp;dispozici židle a&nbsp;věšáky na&nbsp;odložení. Do&nbsp;čisté taneční obuvi se prosím přezouvejte ihned po&nbsp;vstupu na&nbsp;taneční sál, abychom si ho udrželi během lekce čistý a&nbsp;zajistili jeho bezvadný stav.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Oblečení a&nbsp;obuv</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Vezměte si pohodlné oblečení, ve&nbsp;kterém se budete cítit příjemně a&nbsp;pohodlně. Například legíny a&nbsp;top nebo sukni/šaty, které nebrání pohybu. Obuv libovolná, hlavně s&nbsp;měkkou podrážkou, případně přímo taneční obuv. Vyvarujte se kovových podpatků, které by mohly poškrábat parket. Nemělo by se jednat o&nbsp;obuv, ve&nbsp;které jste přišli z&nbsp;venku.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Občerstvení a&nbsp;restaurace</h4>
					<p data-sal="fade" data-sal-duration="1000">
						S sebou na&nbsp;taneční lekci si přineste své vlastní pití. Během nedělní lekce Latin Fever je restaurace Zlonínský Dvůr uzavřena, pro&nbsp;návštěvu toalet prosíme používejte venkovní vchod.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Těším se na&nbsp;vás</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Přineste si dobrou náladu a&nbsp;v&nbsp;případě jakýchkoliv dotazů mě neváhejte kontaktovat.
					</p>

				</div>
			</div>

		</div>

	)
}

export default Fever